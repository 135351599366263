import { Injectable } from '@angular/core';
import { User, UserManager, UserManagerSettings, } from 'oidc-client';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
const apiUmeeUrl = environment.apiKSFBase;
const apiUploadS3Url = environment.apiUploadS3Base;
@Injectable()
export class AuthService {

  private manager: UserManager = new UserManager(environment.authenSettings);
  private user: any = null;
  private account: any = null;
  constructor(private http: HttpClient, private router: Router) {
    this.manager.getUser().then(user => {
      this.user = user;
    });
  }

  isLoggedIn(): Promise<boolean> {
    // localStorage.removeItem('menuItems');
    if (this.user != null) {
      return new Promise((resolve, reject) => resolve(!this.user.expired));
    }

    return this.manager.getUser().then(user => {
      this.user = user;
      return this.user != null && !user.expired;
    });
  }

  getClaims(): any {
    return this.user.profile;
  }

  getPermissions(): any {
    return this.account;
  }

  getAuthorizationHeaderValue(): string {
    return `Bearer ${this.user.access_token}`;
  }

  getAccessTokenValue(): string {
    return this.user.access_token;
  }

  getUserName(): string {
    return this.user.profile.preferred_username;
  }

  isExpired(): boolean {
    return this.user.expired
  }

  startAuthentication(): Promise<void> {
    return this.manager.signinRedirect();
  }

  signout(): Promise<void> {
    localStorage.removeItem('menuItems');
    localStorage.removeItem('tokenFb');
    localStorage.removeItem('account');
    return this.manager.signoutRedirect();
  }

  completeAuthentication(): Promise<void> {
    localStorage.setItem('projectCd', '01');
    return this.manager.signinRedirectCallback()
      .then(user => { this.user = user; })
      .then(_ => this.getEmpDetail());
  }

  getUserImage(): string {
    return localStorage.getItem('avatarUrl') || '';
  }

   getEmpDetail() {
     if(this.user) {
      const returnUrl = localStorage.getItem('returnUrl');
      if (returnUrl) {
        this.router.navigateByUrl(returnUrl);
      }
      this.getAccount();
     }
  }
  

  getAccount() {
    if(this.user) {
     const options = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
       })
     };
     this.getTokenS3(this.user.access_token, options).subscribe(result => {
       if (result) {
        localStorage.setItem('access_token-s3', JSON.stringify(result.jwt));
       }
     })
    }
 }

  getAccountLogin(options): Observable<any> {
    return this.http.post(`${apiUmeeUrl}/api/v1/account/Login`, {},options);
  }

  getTokenS3(access_token, options): Observable<any> {
    return this.http.get(`${apiUploadS3Url}/auth/keycloak/callback?access_token=${access_token}`,options);
  }
  // https://dev-umee-finance-strapi.ssf.vn/auth/keycloak/callback?access_token
  getWorkingProject() {
    return localStorage.getItem('projectCd');
  }
}

