import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import {InputTextModule} from 'primeng/inputtext';
import { HomeComponent } from './pages/home/home.component';
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';
import { AppRoutingModule } from './app.routing';
import { SharedCommonModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToolbarModule } from 'primeng/toolbar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { MenuModule } from 'primeng/menu';
import { FooterComponent } from './shared/footer/footer.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import {MenubarModule} from 'primeng/menubar';
import { AuthInterceptor } from './services/auth-interceptor';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';
import { ApiService } from './services/api.service';
import { FeedBaseService } from './services/firebase.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CustomTooltipComponent } from './common/ag-component/customtooltip.component';
import { DefaultLayoutPosComponent } from './containers/default-layout-pos/default-layout-pos.component';
import {TabMenuModule} from 'primeng/tabmenu';
import {DropdownModule} from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import {TimelineModule} from 'primeng/timeline';
import {ChartModule} from 'primeng/chart';
import { AgGridModule } from '@ag-grid-community/angular';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService } from 'primeng/dynamicdialog';

const APP_CONTAINERS = [DefaultLayoutComponent, DefaultLayoutPosComponent];
@NgModule({ 
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    HomeComponent,
    AuthCallbackComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    CustomTooltipComponent,
   
  
   
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    InputTextModule,
    SharedCommonModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    HttpClientModule,
    ToolbarModule,
    NgxSpinnerModule,
    SplitButtonModule,
    AvatarModule,
    BadgeModule,
    MenuModule,
    MenubarModule,
    TabMenuModule,
    DropdownModule,
    ToastModule,
    ConfirmDialogModule,
    TimelineModule,
    ChartModule,
    AgGridModule.withComponents([
      CustomTooltipComponent
    ]),
  ],
  providers: [
    AuthService,
    AuthGuardService,
    ApiService,
    FeedBaseService,
    MessageService,
    DialogService,
    ConfirmationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
