export const ACTIONS = {
    ADD: 'them_moi',
    EDIT: 'sua_thong_tin',
    VIEW: 'xem_chi_tiet',
    CHI_TIET_THONG_TIN_DONG_TIEN: 'chi_tiet_thong_tin_dong_tien',
    DELETE: 'xoa_thong_tin',
    CHANGE_STATUS: 'thay_doi_trang_thai',
    NUMBER_CONTRACT: 'so_hop_dong',
    TRINH_DUYET: 'trinh_duyet',
    HUY_TRINH_DUYET: 'huy_trinh_duyet',
    KHOA_CAN: 'khoa_can',
    HUY_HOP_DONG: 'huy_hop_dong',
    THANH_TOAN: 'thanh_toan',
    PHE_DUYET: 'phe_duyet',
    HUY_PHE_DUYET: 'huy_phe_duyet',
    CHI_TIEN: 'chi_tien',
    CAP_NHAT_HO_SO: 'cap_nhat_ho_so',
    CAP_NHAT_HO_SO_OFFLINE: 'cap_nhat_ho_so_offline',
    DOWLOAD_HO_SO: 'dowload_ho_so',
    DOWLOAD_HO_SO_DA_KY: 'dowload_ho_so_da_ky',
    XEM_HO_SO: 'xem_ho_so',
    UPLOAD_HO_SO_DA_KY: 'upload_ho_so_da_ky',
    GOP_TAI_KHOAN: 'gop_tai_khoan',
    QUAY_LAI_GIAO_DICH: 'quay_lai_giao_dich',
    DUYET_HO_SO: 'duyet_ho_so',
    DUYET_HOA_HONG: 'duyet_hoa_hong',
    DONG_CHINH_SACH: 'dong_chinh_sach',
    CHI_HOA_HONG: 'chi_hoa_hong',
    RUT_VON: 'rut_von',
    TAT_TOAN: 'tat_toan',
    TAT_TOAN_BOND: 'tat_toan_bond',
    CHUYENTAITUC: 'chuyen_tai_tuc',
    DOIMAGIOITHIEU: 'doi_ma_gioi_thieu',
    CHUYEN_NHUONG_HOP_DONG: 'chuyen_nhuong_hop_dong',
    MO_KHOA_CAN: 'mo_khoa_can',
    HUY_CHUYEN_NHUONG: 'huy_chuyen_nhuong',
    GUI_THONG_BAO: 'gui_thong_bao',
    PHONG_TOA: 'phong_toa',
    GIAI_TOA: 'giai_toa',
    DUYET_THANH_TOAN: 'duyet_thanh_toan',
    TAO_THONG_BAO: 'tao_thong_bao',
    GIAO_HOP_DONG: 'giao_hop_dong',
    LAY_MAU_BAN_GIAO: 'lay_mau_ban_giao',
    NHAN_HOP_DONG: 'nhan_hop_dong',
    EXPORT: 'export',
    TAI_TUC_DAU_TU: 'tai_tuc_dau_tu',
    SU_DUNG_TAI_KHOAN: 'su_dung_tai_khoan',
    DONG_TAI_KHOAN: 'dong_tai_khoan',
    CHI_TRA: 'chi_tra',
    LAP_KE_HOACH_CHI_TRA: 'lap_ke_hoach_chi_tra',
    GUI_YEU_CAU_THANH_TOAN: 'gui_yeu_cau_thanh_toan',
    CHUYEN_ONLINE: 'chuyen_online',
    HUY_HO_SO_DA_KY_DUYET: 'huy_ho_so_da_ky_duyet',
    TAI_HO_SO_MAU_2B: 'tai_ho_so_mau_2b',
    DUYET_THANH_TOAN_KHONG_CHI_TIEN: 'duyet_thanh_toan_khong_chi_tien',
    CAP_NHAT: 'cap_nhat',
    GHI_NHAN_KHACH_HANG_SU_DUNG: 'ghi_nhan_khach_hang_su_dung',
    TIM_KIEM_THEO_KEY: 'tim_kiem_theo_key',
    TIM_KIEM_NANG_CAO: 'tim_kiem_nang_cao',
    PRODUCT_DS_CAN_HO_THEM_MOI: 'chi-tiet-san-pham-dau-tu/danh-sach-can-ho/them-moi',
    PRODUCT_DS_CAN_HO_XEM_CHI_TIET: 'chi-tiet-san-pham-dau-tu/danh-sach-can-ho/xem-chi-tiet',
    PRODUCT_DS_CAN_HO_EDIT: 'chi-tiet-san-pham-dau-tu/danh-sach-can-ho/edit',
    PRODUCT_DS_CAN_HO_KHOA_CAN: 'chi-tiet-san-pham-dau-tu/danh-sach-can-ho/khoa_can',
    PRODUCT_DS_CAN_HO_MO_CAN: 'chi-tiet-san-pham-dau-tu/danh-sach-can-ho/mo_can',
    PRODUCT_DS_CAN_HO_DELETE: 'chi-tiet-san-pham-dau-tu/danh-sach-can-ho/delete',
    OPEN_DS_CAN_HO_BAN_HANG: 'chi-tiet-phan-phoi-dau-tu/danh-sach-can-ho/ban-hang',
    OPEN_DS_CAN_HO_HIEN_THI: 'chi-tiet-phan-phoi-dau-tu/danh-sach-can-ho/hien-thi',
    OPEN_CS_LAI_DAU_TU_DELETE: 'chi-tiet-phan-phoi-dau-tu/chinh-sach-lai-dau-tu/delete',
    OPEN_CS_LAI_DAU_TU_XEM_CHI_TIET: 'chi-tiet-phan-phoi-dau-tu/chinh-sach-lai-dau-tu/xem-chi-tiet',
    OPEN_CS_LAI_DAU_TU_EDIT: 'chi-tiet-phan-phoi-dau-tu/chinh-sach-lai-dau-tu/edit',
    OPEN_CS_LAI_DAU_TU_THEM_MOI: 'chi-tiet-phan-phoi-dau-tu/chinh-sach-lai-dau-tu/them-moi',
    OPEN_CS_MUA_BDS_DELETE: 'chi-tiet-phan-phoi-dau-tu/chinh-sach-mua-bds/delete',
    OPEN_CS_MUA_BDS_XEM_CHI_TIET: 'chi-tiet-phan-phoi-dau-tu/chinh-sach-mua-bds/xem-chi-tiet',
    OPEN_CS_MUA_BDS_EDIT: 'chi-tiet-phan-phoi-dau-tu/chinh-sach-mua-bds/edit',
    OPEN_CS_MUA_BDS_THEM_MOI: 'chi-tiet-phan-phoi-dau-tu/chinh-sach-mua-bds/them-moi',
    OPEN_TAI_LIEU_CS_DAU_TU_XEM_CHI_TIET: 'chi-tiet-phan-phoi-dau-tu/tai-lieu-cs-dau-tu/xem-chi-tiet',
    OPEN_TAI_LIEU_CS_DAU_TU_EDIT: 'chi-tiet-phan-phoi-dau-tu/tai-lieu-cs-dau-tu/edit',
    OPEN_TAI_LIEU_CS_DAU_TU_THEM_MOI: 'chi-tiet-phan-phoi-dau-tu/tai-lieu-cs-dau-tu/them-moi',
    OPEN_TAI_LIEU_CS_DAU_TU_DELETE: 'chi-tiet-phan-phoi-dau-tu/tai-lieu-cs-dau-tu/delete',
    CAIDAT_TKTT_XEM_CHI_TIET: 'chi-tiet-khach-hang/tktt/xem-chi-tiet',
    CAIDAT_TKTT_EDIT: 'chi-tiet-khach-hang/tktt/edit',
    CAIDAT_TKTT_ADD: 'chi-tiet-khach-hang/tktt/them',
    CAIDAT_TKTT_DELETE: 'chi-tiet-khach-hang/tktt/xoa',
    QL_NGAY_NGHI_THEM: 'ql-ngay-nghi/add',
    CHITIET_DVTV_TKTT_EDIT: 'chi-tiet-don-vi-tu-van/tktt/edit',
    CHITIET_DVTV_TKTT_ADD: 'chi-tiet-don-vi-tu-van/tktt/add',
    CHITIET_DVTV_TKTT_DELETE: 'chi-tiet-don-vi-tu-van/tktt/delete',
    CHITIET_DVTV_TKTT_VIEW: 'chi-tiet-don-vi-tu-van/tktt/xem-chi-tiet',

    CHITIET_TONGTHAU_TKTT_EDIT: 'chi-tiet-tong-thau/tktt/edit',
    CHITIET_TONGTHAU_TKTT_ADD: 'chi-tiet-tong-thau/tktt/add',
    CHITIET_TONGTHAU_TKTT_DELETE: 'chi-tiet-tong-thau/tktt/delete',
    CHITIET_TONGTHAU_TKTT_VIEW: 'chi-tiet-tong-thau/tktt/xem-chi-tiet',

    CHITIET_CSLDT_CAI_DAT_EDIT: 'chi-tiet-cs-lai-dau-tu/cai-dat/edit',
    CHITIET_CSLDT_CAI_DAT_ADD: 'chi-tiet-cs-lai-dau-tu/cai-dat/add',
    CHITIET_CSLDT_CAI_DAT_DELETE: 'chi-tiet-cs-lai-dau-tu/cai-dat/delete',
    CHITIET_CSLDT_CAI_DAT_VIEW: 'chi-tiet-cs-lai-dau-tu/cai-dat/view',

    CHITIET_CSCD_BDS_CAI_DAT_EDIT: 'chi-tiet-cscd-bds/cai-dat/edit',
    CHITIET_CSCD_BDS_CAI_DAT_ADD: 'chi-tiet-cscd-bds/cai-dat/add',
    CHITIET_CSCD_BDS_CAI_DAT_DELETE: 'chi-tiet-cscd-bds/cai-dat/delete',
    CHITIET_CSCD_BDS_CAI_DAT_VIEW: 'chi-tiet-cscd-bds/cai-dat/view',

    CHITIET_CSHH_SALER_CAI_DAT_CHIET_KHAU_EDIT: 'chi-tiet-cshh-saler-cai-dat-chiet-khau/edit',
    CHITIET_CSHH_SALER_CAI_DAT_CHIET_KHAU_ADD: 'chi-tiet-cshh-saler-cai-dat-chiet-khau/add',
    CHITIET_CSHH_SALER_CAI_DAT_CHIET_KHAU_DELETE: 'chi-tiet-cshh-saler-cai-dat-chiet-khau/delete',
    CHITIET_CSHH_SALER_CAI_DAT_CHIET_KHAU_VIEW: 'chi-tiet-cshh-saler-cai-dat-chiet-khau/view',

    CHITIET_CSHH_SALER_CAI_DAT_CS_BDS_EDIT: 'chi-tiet-cshh-saler-cai-dat-cs-bds/edit',
    CHITIET_CSHH_SALER_CAI_DAT_CS_BDS_ADD: 'chi-tiet-cshh-saler-cai-dat-cs-bds/add',
    CHITIET_CSHH_SALER_CAI_DAT_CS_BDS_DELETE: 'chi-tiet-cshh-saler-cai-dat-cs-bds/delete',
    CHITIET_CSHH_SALER_CAI_DAT_CS_BDS_VIEW: 'chi-tiet-cshh-saler-cai-dat-cs-bds/view',
    OPEN_DANH_SACH_QUA_TANG_XEM_CHI_TIET: 'chi-tiet-phan-phoi-dau-tu/danh-sach-qua-tang/xem-chi-tiet',
    OPEN_DANH_SACH_QUA_TANG_EDIT: 'chi-tiet-phan-phoi-dau-tu/danh-sach-qua-tang/edit',
    OPEN_DANH_SACH_QUA_TANG_THEM_MOI: 'chi-tiet-phan-phoi-dau-tu/danh-sach-qua-tang/them-moi',
    OPEN_DANH_SACH_QUA_TANG_DELETE: 'chi-tiet-phan-phoi-dau-tu/danh-sach-qua-tang/delete',
    ORDER_GIAO_DICH_XEM_CHI_TIET: 'chi-tiet-so-lenh/giao-dich/xem-chi-tiet',
    ORDER_GIAO_DICH_EDIT: 'chi-tiet-so-lenh/giao-dich/edit',
    ORDER_GIAO_DICH_THEM_MOI: 'chi-tiet-so-lenh/giao-dich/them-moi',
    ORDER_GIAO_DICH_DELETE: 'chi-tiet-so-lenh/giao-dich/delete',
    ORDER_GIAO_DICH_CHI_TIEN: 'chi-tiet-so-lenh/giao-dich/chi-tien',
    ORDER_GIAO_DICH_DUYET_GIAO_DICH: 'chi-tiet-so-lenh/giao-dich/duyet-thanh-toan',
    ORDER_GIAO_DICH_HUY_PHE_DUYET_GIAO_DICH: 'chi-tiet-so-lenh/giao-dich/huy-phe-duyet-giao-dich',
    ORDER_GIAO_DICH_PHE_DUYET: 'chi-tiet-so-lenh/giao-dich/phe-duyet',
    ORDER_GIAO_DICH_HUY_DUYET: 'chi-tiet-so-lenh/giao-dich/huy-duyet',
    ORDER_GIAO_DICH_TAO_THONG_BAO: 'chi-tiet-so-lenh/giao-dich/tao-thong-bao',
    ORDER_DS_HO_SO_KHACH_HANG_KY_HUY_HO_SO_KY_DUYET: 'chi-tiet-so-lenh/ds-ho-so-khach-hang-ky/huy-ho-so-ky-duyet',
    ORDER_DS_HO_SO_KHACH_HANG_KY_TAI_LEN_HS_DA_KY_DUYET: 'chi-tiet-so-lenh/ds-ho-so-khach-hang-ky/tai-len-ho-so-da-ky-duyet',
    ORDER_DS_HO_SO_KHACH_HANG_KY_TAI_VE_HO_SO_DA_KY_DUYET: 'chi-tiet-so-lenh/ds-ho-so-khach-hang-ky/tai-ve-ho-so-da-ky-duyet',
    ORDER_DS_HO_SO_KHACH_HANG_KY_VIEW_HO_SO_MAU_2B: 'chi-tiet-so-lenh/ds-ho-so-khach-hang-ky/view-ho-so-mau-2b',
    ORDER_DS_HO_SO_KHACH_HANG_KY_DOWLOAD_HO_SO_MAU_2B: 'chi-tiet-so-lenh/ds-ho-so-khach-hang-ky/dowload-ho-so-mau-2b',
    ORDER_DS_KHACH_HO_SO_KHACH_HANG_KY_VIEW_HO_SO_MAU: 'chi-tiet-so-lenh/ds-ho-so-khach-hang-ky/view-ho-so-mau',
    ORDER_DS_HO_SO_KHACH_HANG_KY_DOWLOAD_HO_SO_MAU: 'chi-tiet-so-lenh/ds-ho-so-khach-hang-ky/dowload-ho-so-mau',
    ORDER_DS_HO_SO_KHACH_HANG_KY_CHUYEN_ONLINE: 'chi-tiet-so-lenh/ds-ho-so-khach-hang-ky/chuyen-online',
    ORDER_DS_HO_SO_KHACH_HANG_KY_DUYET_HO_SO: 'chi-tiet-so-lenh/ds-ho-so-khach-hang-ky/duyet-ho-so',
    ORDER_DS_HO_SO_KHACH_HANG_KY_CAP_NHAT_HO_SO: 'chi-tiet-so-lenh/ds-ho-so-khach-hang-ky/cap-nhat-ho-so',
    ORDER_DANH_SACH_UU_DAI_XEM_CHI_TIET: 'chi-tiet-so-lenh/danh-sach-uu-dai/xem-chi-tiet',
    ORDER_DANH_SACH_UU_DAI_EDIT: 'chi-tiet-so-lenh/danh-sach-uu-dai/edit',
    ORDER_DANH_SACH_UU_DAI_DELETE: 'chi-tiet-so-lenh/danh-sach-uu-dai/delete',
    VOUCHER_HOP_DONG_PHAN_PHOI_ADD: 'DS_HOP_DONG_PHAN_PHOI/ADD',
    VOUCHER_HOP_DONG_PHAN_PHOI_EDIT: 'DS_HOP_DONG_PHAN_PHOI/EDIT',
    VOUCHER_HOP_DONG_PHAN_PHOI_DELETE: 'DS_HOP_DONG_PHAN_PHOI/DELETE',
}
export const MessageErrorAction = {
    ERROR_GUI_THONG_BAO: 'Bạn không có quyền gửi thông báo',
    ERROR_PHONG_TOA: 'Bạn không có quyền phong toả',
    ERROR_GIAI_TOA: 'Bạn không có quyền giải toả',
    ERROR_ADD: 'Bạn không có quyền thêm mới',
    ERROR_EDIT: 'Bạn không có quyền sửa',
    ERROR_DELETE: 'Bạn không có quyền xóa',
    ERROR_VIEW: 'Bạn không có quyền xem chi tiết',
    ERROR_CHANGE_STATUS: 'Bạn không có quyền thay đổi trạng thái',
    ERROR_NUMBER_CONTRACT: 'Bạn không có quyền xem số hợp đồng',
    ERROR_TRINH_DUYET: 'Bạn không có quyền trình duyệt',
    ERROR_HUY_TRINH_DUYET: 'Bạn không có quyền hủy trình duyệt',
    ERROR_KHOA_CAN: 'Bạn không có quyền khóa căn hộ',
    ERROR_THANH_TOAN: 'Bạn không có quyền thanh toán',
    ERROR_PHE_DUYET: 'Bạn không có quyền phê duyệt',
    ERROR_HUY_PHE_DUYET: 'Bạn không có quyền hủy phê duyệt',
    ERROR_CHI_TIEN: 'Bạn không có quyền thực hiện chi tiền',
    ERROR_CAP_NHAT_HO_SO: 'Bạn không có quyền thực hiện cập nhật hồ sơ',
    ERROR_DOWLOAD_HO_SO: 'Bạn không có quyền thực hiện dowload hồ sơ',
    ERROR_DOWLOAD_HO_SO_DA_KY: 'Bạn không có quyền thực hiện dowload hồ sơ đã ký',
    ERROR_XEM_HO_SO: 'Bạn không có quyền thực hiện xem hồ sơ',
    ERROR_UPLOAD_HO_SO_DA_KY: 'Bạn không có quyền thực hiện upload hồ sơ đã ký',
    ERROR_CHI_TIET_THONG_TIN_DONG_TIEN: 'Bạn không có quyền xem thông tin dòng tiền',
    ERROR_QUAY_LAI_GIAO_DICH: 'Bạn không có quyền thực hiện quay lại giao dịch',
    ERROR_DUYET_HO_SO: 'Bạn không có quyền thực hiện duyệt hồ sơ',
    ERROR_DUYET_HOA_HONG: 'Bạn không có quyền thực hiện duyệt hoa hồng',
    ERROR_CHI_HOA_HONG: 'Bạn không có quyền thực hiện chi hoa hồng',
    ERROR_RUT_VON: 'Bạn không có quyền thực hiện rút vốn',
    ERROR_TAT_TOAN: 'Bạn không có quyền thực hiện Tất toán',
    ERROR_CHUYEN_NHUONG_HOP_DONG: 'Bạn không có quyền thực chuyển nhượng hợp đồng',
    ERROR_MO_KHOA_CAN: 'Bạn không có quyền thực mở khóa căn',
    ERROR_HUY_CHUYEN_NHUONG: 'Bạn không có quyền thực hủy chuyển nhượng',
    ERROR_DUYET_THANH_TOAN: 'Bạn không có quyền duyệt thanh toán',
    ERROR_TAO_THONG_BAO: 'Bạn không có quyền tạo thông báo',
    ERROR_GIAO_HOP_DONG: 'Bạn không có quyền giao hợp đồng',
    ERROR_LAY_MAU_BAN_GIAO: 'Bạn không có quyền lấy mẫu bàn giao',
    ERROR_NHAN_HOP_DONG: 'Bạn không có quyền nhận hợp đồng',
    ERROR_CAP_NHAT_HO_SO_OFFLINE: 'Bạn không có quyền cập nhật hồ sơ offline',
    ERROR_EXPORT: 'Bạn không có quyền export',
    ERROR_TAI_TUC_DAU_TU: 'Bạn không có quyền tái tục đầu tư',
    ERROR_CHUYENTAITUC: 'Bạn không có quyền chuyển tái tục',
    ERROR_DOIMAGIOITHIEU: 'Bạn không có quyền đổi mã giới thiệu',
    ERROR_GUI_YEU_CAU_THANH_TOAN: 'Bạn không có quyền gửi yêu cầu thanh toán',
    ERROR_CHUYEN_ONLINE: 'Bạn không có quyền chuyển online',
    ERROR_HUY_HO_SO_DA_KY_DUYET: 'Bạn không có quyền hủy hồ sơ đã ký duyệt',
    ERROR_TAI_HO_SO_MAU_2B: 'Bạn không có quyền hủy hồ sơ đã ký duyệt',
    ERROR_DUYET_THANH_TOAN_KHONG_CHI_TIEN: 'Bạn không có quyền hủy hồ sơ đã ký duyệt',
    ERROR_LAP_KE_HOACH_CHI_TRA: 'Bạn không có quyền hủy hồ sơ đã ký duyệt',
    ERROR_CHI_TRA: 'Bạn không có quyền hủy hồ sơ đã ký duyệt',
    ERROR_CAP_NHAT: 'Bạn không có quyền hủy hồ sơ đã ký duyệt',
    ERROR_GHI_NHAN_KHACH_HANG_SU_DUNG: 'Bạn không có quyền hủy hồ sơ đã ký duyệt',
    ERROR_TIM_KIEM_THEO_KY: 'Bạn không có quyền hủy hồ sơ đã ký duyệt',
    ERROR_TIM_KIEM_NANG_CAO: 'Bạn không có quyền hủy hồ sơ đã ký duyệt',
    ERROR_PRODUCT_DS_CAN_HO_THEM_MOI: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_PRODUCT_DS_CAN_HO_XEM_CHI_TIET: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_PRODUCT_DS_CAN_HO_EDIT: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_PRODUCT_DS_CAN_HO_KHOA_CAN: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_PRODUCT_DS_CAN_HO_MO_CAN: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_PRODUCT_DS_CAN_HO_MO_DELETE: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_OPEN_DS_CAN_HO_BAN_HANG: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_OPEN_DS_CAN_HO_HIEN_THI: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_OPEN_CS_LAI_DAU_TU_DELETE: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_OPEN_CS_LAI_DAU_TU_EDIT: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_OPEN_CS_LAI_DAU_TU_XEM_CHI_TIET: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_OPEN_CS_LAI_DAU_TU_THEM_MOI: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_OPEN_CS_MUA_BDS_EDIT: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_OPEN_CS_MUA_BDS_XEM_CHI_TIET: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_OPEN_CS_MUA_BDS_THEM_MOI: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_OPEN_TAI_LIEU_CS_DAU_TU_EDIT: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_OPEN_TAI_LIEU_CS_DAU_TU_XEM_CHI_TIET: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_OPEN_TAI_LIEU_CS_DAU_TU_THEM_MOI: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_OPEN_TAI_LIEU_CS_DAU_TU_DELETE: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_OPEN_DANH_SACH_QUA_TANG_EDIT: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_OPEN_DANH_SACH_QUA_TANG_XEM_CHI_TIET: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_OPEN_DANH_SACH_QUA_TANG_THEM_MOI: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_OPEN_DANH_SACH_QUA_TANG_DELETE: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_GIAO_DICH_XEM_CHI_TIET: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_GIAO_DICH_EDIT: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_GIAO_DICH_THEM_MOI: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_GIAO_DICH_CHI_TIEN: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_GIAO_DICH_DELETE: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_GIAO_DICH_DUYET_GIAO_DICH: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_GIAO_DICH_HUY_PHE_DUYET_GIAO_DICH: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_GIAO_DICH_PHE_DUYET: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_GIAO_DICH_HUY_DUYET: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_GIAO_DICH_TAO_THONG_BAO: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_DS_HO_SO_KHACH_HANG_KY_HUY_HO_SO_KY_DUYET: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_DS_HO_SO_KHACH_HANG_KY_TAI_LEN_HS_DA_KY_DUYET: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_DS_HO_SO_KHACH_HANG_KY_TAI_VE_HO_SO_DA_KY_DUYET: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_DS_HO_SO_KHACH_HANG_KY_VIEW_HO_SO_MAU_2B: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_DS_HO_SO_KHACH_HANG_KY_DOWLOAD_HO_SO_MAU_2B: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_DS_KHACH_HO_SO_KHACH_HANG_KY_VIEW_HO_SO_MAU: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_DS_HO_SO_KHACH_HANG_KY_DOWLOAD_HO_SO_MAU: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_DS_HO_SO_KHACH_HANG_KY_CHUYEN_ONLINE: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_DS_HO_SO_KHACH_HANG_KY_DUYET_HO_SO: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_DS_HO_SO_KHACH_HANG_KY_CAP_NHAT_HO_SO: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_DANH_SACH_UU_DAI_XEM_CHI_TIET: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_DANH_SACH_UU_DAI_EDIT: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
    ERROR_ORDER_DANH_SACH_UU_DAI_DELETE: 'Bạn không có quyền vui lòng liên hệ với quản trị !',
}

export const MENUACTIONROLEAPI = {
    'getCustPage': {
        'name': 'Quản lý khách hàng',
        'api': '0eed6cc8-55f4-4285-9f8e-bfc0ba572253',
        'menu': '',
        'url': '/cai-dat/quan-ly-khach-hang'
    },
    'GetHolidayPage': {
        'name': 'Quản lý ngày nghỉ',
        'api': 'e31c63c5-1b71-493d-8132-f9e72a41762a',
        'menu': '',
        'url': '/cai-dat/quan-ly-ngay-nghi'
    },
    'GetCooperatorPage': {
        'name': 'Tổng thầu',
        'api': 'f4ef5cd6-2a80-4cff-bc77-6264ade66d29',
        'menu': '',
        'url': '/cai-dat/tong-thau'
    },
    'GetAccountPage': {
        'name': 'Tài khoản ngân hàng',
        'api': 'fff8cc75-cfd0-4362-9aa6-e75918822943',
        'menu': '',
        'url': '/cai-dat/tai-khoan-ngan-hang'
    },
    'GetOwnerPage': {
        'name': 'Đơn vị tư vấn',
        'api': 'ed9ca876-348b-4354-9b8a-d7ee6a24eef8',
        'menu': '',
        'url': '/cai-dat/don-vi-tu-van'
    },
    'GetWorkflowPage': {
        'name': 'Phê Duyệt',
        'api': '5e12b253-42c3-40e6-b1d5-fadbc548a565',
        'menu': '',
        'url': '/cai-dat/phe-duyet'
    },
    'GetIntroPage': {
        'name': 'Giới thiệu dự án',
        'api': 'cf6a5964-e823-4584-a2ff-715693bbd80b',
        'menu': '',
        'url': '/cai-dat/gioi-thieu-du-an'
    },
    'GetConsultantPage': {
        'name': 'Danh Sách chuyên viên tư vấn',
        'api': 'cc176324-5501-49d8-959d-1e68ed531f01',
        'menu': '',
        'url': '/cai-dat/danh-sach-chuyen-vien-tu-van'
    },
    'GetSchemePage': {
        'name': 'Chính sách đầu tư',
        'api': '2bcfcdbc-5e2d-4bc3-b2ad-bf93dcc14b1b',
        'menu': '',
        'url': '/chinh-sach/chinh-sach-lai-dau-tu'
    },
    'GetConvertPage': {
        'name': 'Chính sách chuyển đổi BĐs',
        'api': 'dd03dd78-aa1b-4c37-9f46-b097e1defccf',
        'menu': '',
        'url': '/chinh-sach/chinh-sach-chuyen-doi-bds'
    },
    'GetAgencyPolicyPage': {
        'name': 'Chính sách ưu đãi',
        'api': '26710802-5584-497c-b805-238c73658327',
        'menu': '',
        'url': '/chinh-sach/chinh-sach-hoa-hong-saler'
    },
    'GetTransactionPage': {
        'name': 'Giao dịch',
        'api': '5857cda7-79e9-48e3-820b-6f9c9e601a74',
        'menu': '',
        'url': '/truy-van/giao-dich'
    },
    'GetExchangePage': {
        'name': 'Thị trường thứ cấp',
        'api': '17acead8-4474-456c-b160-2f7a7f22e7d8',
        'menu': '',
        'url': '/truy-van/thi-truong-thu-cap'
    },
    'GetTransBankPage': {
        'name': 'Thu tiền ngân hàng',
        'api': 'a170b111-b081-46a3-95fa-5b67f8198124',
        'menu': '',
        'url': '/truy-van/thu-tien-ngan-hang'
    },
    'GetTransBankPayonPage': {
        'name': 'Chi Tiền ngân hàng',
        'api': '3a9c8af6-90ea-476b-bcb8-e102f208ab63',
        'menu': '',
        'url': '/truy-van/chi-tien-ngan-hang'
    },
    'GetTransAccounts': {
        'name': 'Sao kê tài khoản',
        'api': '8e5b759e-4c0c-4883-b757-df6e2b95b313',
        'menu': '',
        'url': '/truy-van/sao-ke-tai-khoan'
    },
    'GetOrderPage': {
        'name': 'Sổ lệnh',
        'api': 'ec450d33-137a-40b2-87f7-0a149352af5a',
        'menu': '',
        'url': '/quan-ly-hop-dong/so-lenh',
        'order': '/quan-ly-hop-dong/so-lenh',
        'orderconvert': '/hop-dong-chuyen-doi/so-lenh'
    },
    'GetOrderPage1': {
        'name': 'Xử lý đặt cọc',
        'api': 'f5bdf018-1924-44c9-80e1-c55d5803830f',
        'menu': '',
        'url': ''
    },
    'GetOrderPage2': {
        'name': 'Xử lý hợp đồng',
        'api': '7b1581d1-4b2a-4f96-bcf0-120bbcb4088b',
        'menu': '',
        'url': '/quan-ly-hop-dong/xu-ly-hop-dong',
        'order': '/quan-ly-hop-dong/xu-ly-hop-dong',
        'orderconvert': '/hop-dong-chuyen-doi/xu-ly-hop-dong'
    },
    'GetOrderContPage': {
        'name': 'Giao nhận hợp đồng',
        'api': '7630401b-30db-48d0-901e-4b2ec42a3183',
        'menu': '',
        'url': '/quan-ly-hop-dong/giao-nhan-hop-dong',
        'order': '/quan-ly-hop-dong/giao-nhan-hop-dong',
        'orderconvert': '/hop-dong-chuyen-doi/giao-nhan-hop-dong'
    },
    'GetOrderPage3': {
        'name': 'Hợp Đồng',
        'api': '58af1981-b3a9-41c2-aebd-5009bed7693c',
        'menu': '',
        'url': '/quan-ly-hop-dong/hop-dong',
        'order': '/quan-ly-hop-dong/hop-dong',
        'orderconvert': '/hop-dong-chuyen-doi/hop-dong'
    },
    'GetOrderInterestPage': {
        'name': 'Chi trả lãi',
        'api': '8d14a80b-2f28-4971-8f55-a5990ac16c6b',
        'menu': '',
        'url': '/quan-ly-hop-dong/chi-tra-lai',
        'order': '/quan-ly-hop-dong/chi-tra-lai',
        'orderconvert': '/hop-dong-chuyen-doi/chi-tra-lai'
    },
    'GetTransBankAccountPage': {
        'name': 'Danh sách sao kê tài khoản',
        'api': '8d14a80b-2f28-4971-8f55-a5990ac16c6b',
        'menu': '',
        'url': '/truy-van/sao-ke-tai-khoan'
    },
    'GetOrderWithdrawalPage': {
        'name': 'Xứ lý rút vốn',
        'api': 'c0e2410a-bf14-46af-8653-48db0279bd35',
        'menu': '',
        'url': '/quan-ly-hop-dong/xu-ly-rut-von',
        'order': '/quan-ly-hop-dong/xu-ly-rut-von',
        'orderconvert': '/hop-dong-chuyen-doi/xu-ly-rut-von'
    },
    'GetLiquidationPage': {
        'name': 'Tất toán trước hạn',
        'api': 'bce542e1-735e-47cc-9841-e5a9bf1847b0',
        'menu': '',
        'url': '/quan-ly-hop-dong/tat-toan-truoc-han',
        'order': '/quan-ly-hop-dong/tat-toan-truoc-han',
        'orderconvert': '/hop-dong-chuyen-doi/tat-toan-truoc-han'
    },
    'GetOrderFinalPage': {
        'name': 'Hợp đồng đáo hạn',
        'api': '8a11a88a-d927-46ce-8b9e-dd3de31f112f',
        'menu': '',
        'url': '/quan-ly-hop-dong/dao-han-hop-dong',
        'order': '/quan-ly-hop-dong/dao-han-hop-dong',
        'orderconvert': '/hop-dong-chuyen-doi/dao-han-hop-dong'
    },
    'GetOrderHistoryPage': {
        'name': 'Lịch sử đầu tư',
        'api': '4ed1e28e-ffcb-4396-a927-4f344fccc0ea',
        'menu': '',
        'url': '/quan-ly-hop-dong/lich-su-dau-tu',
        'order': '/quan-ly-hop-dong/lich-su-dau-tu',
        'orderconvert': '/hop-dong-chuyen-doi/lich-su-dau-tu'
    },
    'GetVoucherPage': {
        'name': 'Chi trả ưu đãi',
        'api': '0f2e8a14-9cab-4ede-bd36-2a5b8a4dc0ba',
        'menu': '',
        'url': '/quan-ly-hop-dong/chi-tra-uu-dai'
    },
    'GetOrderAgentPage': {
        'name': 'Chi trả hoa hồng',
        'api': '22151eec-2413-49c3-9788-446eaec1bfb8',
        'menu': '',
        'url': ''
    },
    'GetParameterPage': {
        'name': 'Tham số đầu tư',
        'api': 'af9992ad-e5d0-4f09-b3d5-46c5ac1b0bbd',
        'menu': '',
        'url': '/quan-ly-dau-tu/tham-so-dau-tu'
    },
    'GetProductPage': {
        'name': 'Sản phẩm đầu tư',
        'api': 'a14c709a-c6c7-4e8d-bcd8-18c4182da838',
        'menu': '',
        'url': '/quan-ly-dau-tu/san-pham-dau-tu'
    },
    'GetOpenPage': {
        'name': 'Phân phối đầu tư',
        'api': '8f1a6bb1-931a-40d3-a638-df542bcaee9d',
        'menu': '',
        'url': '/quan-ly-dau-tu/phan-phoi-dau-tu'
    },
    'GIAO_DICH': {
        'name': 'Giao dịch (sổ lệnh)',
        'api': '8f1a6bb1-931a-40d3-a638-df542bcaee9d',
        'menu': '',
        'url': '/phan-quyen-so-lenh/giao-dich'
    },
    'HO_SO_KHACH_HANG_KY': {
        'name': 'Danh mục hồ sơ khách hàng ký',
        'api': '8f1a6bb1-931a-40d3-a638-df542bcaee9d',
        'menu': '',
        'url': '/phan-quyen-so-lenh/danh-muc-ho-so-khach-hang-ky'
    },
}

export const statusCodeChangeTK = {
    khoi_tao: 0, //'Khởi tạo',
    cho_DVKH_phe_duyet: 1, //'Chờ DVKH phê duyệt',
    DVKH_tu_choi: 2, //'DVKH Từ chối',
    DVKH_phe_duyet: 3, //'DVKH phê duyệt',
  }